//a2n jameshan code

import ClickEvent = JQuery.ClickEvent

type Category = 'region' | 'domain' | 'state'

// Sidebar "data-framer-name" categories
const A2N_SIDEBAR_LIST = ['Domains', 'Regions', 'States']
// Custom no show class used for selecting which cards are being shown
const NO_SHOW_CLASS = 'a2n-no-show'
// Sidebar "data-framer-name" mapping to card "data-framer-name"
const MAPPED_DATA_TYPE_FOR_A2N_SIDEBAR: {[key: string]: Category} = {
  'Regions': 'region',
  'Domains': 'domain',
  'States': 'state'
}
// This contains the currently applied filters
const SELECTED_A2N_MINISTRY_FILTER: {[key: string]: string} = {}

/**
 * Retrieves all the cards that are present on the page
 *
 * If allCards is set to true, then it will show all the cards including the hidden ones.
 *
 * @param {'region'|'domain'|'state'} type
 * @param {boolean} allCards
 * @returns jquery objects of all the cards
 */
function getAllA2NCards(type: 'region' | 'domain' | 'state', allCards: boolean): JQuery {
  let css = ''
  if (!allCards) {
    css = ':not(.a2n-no-show)'
  }
  return $(`div[data-framer-name="Ministry Unit"]${css} div[data-framer-name="Metadata"] div[data-framer-name="${type}"] p`)
}

/**
 * 
 * @param allCards boolean
 * @returns 
 */
function getAllNamesCards(allCards: boolean) {
  let css = ''
  if (!allCards) {
    css = ':not(.a2n-no-show)';
  }
  return $(`div[data-framer-name="Ministry Unit"]${css} div[data-framer-name="Title-Sub"] div[data-framer-name="Name"] p`)
}
/**
 * Looks at all the cards that are visible and builds the corresponding sidebars
 */
function traverseAndBuildSidebar() {
  const regionMetadata = getAllA2NCards('region', false)
  const domainMetadata = getAllA2NCards('domain', false)
  const stateMetadata = getAllA2NCards('state', false)

  // retrieve unique dataset so that we can have corresponding sidebar options
  const uniqueRegionsPresent = [...new Set(regionMetadata.map((_, elem) => elem && elem.innerHTML))]
  const uniqueDomainPresent = [...new Set(domainMetadata.map((_, elem) => elem && elem.innerHTML))]
  const uniqueStatePresent = [...new Set(stateMetadata.map((_, elem) => elem && elem.innerHTML))]

  const sidebarData = {
    domains: uniqueDomainPresent,
    regions: uniqueRegionsPresent,
    states: uniqueStatePresent
  }
  buildMinistriesSidebars(sidebarData)
}

function buildMinistriesSidebars(sidebarData: {domains: string[], regions: string[], states: string[]}) {
  buildMinistriesSidebar(sidebarData.domains, 'Domains')
  buildMinistriesSidebar(sidebarData.regions, 'Regions')
  buildMinistriesSidebar(sidebarData.states, 'States')
}

function buildMinistriesSidebar(existingData: string[], type: string) {
  // e.g. structure
  // <div data-framer-name="Sidebar">
  //    <div data-framer-name="Regions">
  //       <p>
  const sidebar = $(`div[data-framer-name="Sidebar"] div[data-framer-name="${type}"] p`)
  sidebar.map((_, elem) => {
    const sidebarData = elem.innerHTML
    // this div is the region/state/domain item that we are hiding/showing
    const parentDiv = $(elem).parent('div')

    if (existingData.indexOf(sidebarData) == -1) {
      parentDiv.hide(0)
    } else {
      parentDiv.show(0)
      if (existingData.length !== 1) {
        // if this is not the selected one, switch back to cursor pointer
        parentDiv.css('cursor', 'pointer')
      }
    }
  })
}

/**
 * Given the selected item, hide all the cards ("Ministry Unit") that are not related to that item
 */
function hideAllNonSelectedCards(selectedData: string, type: Category) {
  getAllA2NCards(type, false).each((_, elem) => {
    const sidebarData = elem.innerHTML

    const parentCard = $(elem).parents('div[data-framer-name="Ministry Unit"]')
    if (selectedData != sidebarData) {
      parentCard.addClass(NO_SHOW_CLASS)
    }
  })
  SELECTED_A2N_MINISTRY_FILTER[type] = selectedData
  setTimeout(() => {
    window.scroll(0, 1);
  }, 100);
}

/**
 * Reset all the cards based on the category. Show all the cards that were hidden based on the type
 */
function resetA2NMinistries(type: Category) {
  delete SELECTED_A2N_MINISTRY_FILTER[type]
  getAllA2NCards(type, true).each((_, elem) => {
    const metaData = $(elem).parents('div[data-framer-name="Metadata"]')
    const parentCard = $(elem).parents('div[data-framer-name="Ministry Unit"]')
    const regionData = metaData.find('div[data-framer-name="region"] p').text()
    const stateData = metaData.find('div[data-framer-name="state"] p').text()
    const domainData = metaData.find('div[data-framer-name="domain"] p').text()

    let shouldKeep = true
    // if the filter is still applied, then we shouldn't show the ones that are not related to that filter
    for (let [key, value] of Object.entries(SELECTED_A2N_MINISTRY_FILTER)) {
      switch (key) {
        case 'region':
          shouldKeep = shouldKeep && value == regionData
          break
        case 'domain':
          shouldKeep = shouldKeep && value == domainData
          break
        case 'state':
          shouldKeep = shouldKeep && value == stateData
          break
      }
    }

    if (shouldKeep) {
      parentCard.removeClass(NO_SHOW_CLASS)
    } else {
      parentCard.addClass(NO_SHOW_CLASS)
    }
  })
}

/**
 * Click handler for each of the menu item
 *
 * @param {Event} e
 */
function filterA2NMinistries(e: ClickEvent) {
  const clickedElem = $(e.currentTarget)
  // find the item that was clicked ("Southwest", "East", etc)
  const clickedData = clickedElem.find('p').text()
  // find the category it's in - "Regions", "States", "Domains"
  const dataType = clickedElem.parent('div').attr('data-framer-name')!
  const resetButton = clickedElem.parents(`div[data-framer-name="${dataType.slice(0, -1)}"]`).find('div[data-framer-name="Reset"]')
  resetButton.css('opacity', 1)
  resetButton.css('cursor', 'pointer')
  clickedElem.css('cursor', 'default')
  hideAllNonSelectedCards(clickedData, MAPPED_DATA_TYPE_FOR_A2N_SIDEBAR[dataType])
  traverseAndBuildSidebar()
}

/**
 * Click handler for the reset button
 *
 * @param {Event} e
 */
function resetA2NMinistriesHandler(e: ClickEvent) {
  const clickedElem = $(e.currentTarget)
  const dataType = clickedElem.parent('div').attr('data-framer-name')
  resetA2NMinistries(MAPPED_DATA_TYPE_FOR_A2N_SIDEBAR[dataType + 's'])
  clickedElem.css('cursor', 'default')
  clickedElem.css('opacity', 0)
  traverseAndBuildSidebar()
}

/**
 * Up arrow to scroll to the top
 */
function addUpArrow() {
  const upArrowComponent = $('div[data-framer-name="Up Arrow"]');
  if (!upArrowComponent) {
    return;
  }
  const upArrow = upArrowComponent.parent('div').parent('div');
  upArrow.click(() => {
    $("html, body").animate({ scrollTop: 0 });
  });
  upArrow.css('cursor', 'pointer');

  $(window).scroll(() => {
    if (window.scrollY == 0) {
      upArrow.css("opacity", 0);
    } else {
      const cards = getAllA2NCards('region', false);
      if (cards.length > 12) {
        upArrow.css("opacity", 1);
      }
    }
  });
}

/**
 * Init where it starts
 *
 * Build the sidebar - sees all the existing cards and build out which menu item should be visible
 * Initiate click handlers
 * Initiate reset button handlers
 */
export function initA2NMinistries() {
  traverseAndBuildSidebar()
  const reset = $('div[data-framer-name="Sidebar"] div[data-framer-name="Reset"]')
  reset.click(resetA2NMinistriesHandler)
  A2N_SIDEBAR_LIST.forEach(sidebarType => {
    const sideBarItem = $(`div[data-framer-name="Sidebar"] div[data-framer-name="${sidebarType}"] div`)
    sideBarItem.click(filterA2NMinistries)
    sideBarItem.css('cursor', 'pointer')
  })
  addUpArrow();
}